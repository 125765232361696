<template>
    <div class="mobile-box">
        <div class="mobile-container">
            <img src="@/assets/logo_seu-idioma.png" alt="Company Logo" class="company-image" />
            <h2 class="mobile-title">Código de Verificação</h2>
            <p class="instruction">
                Enviamos um código de verificação para o seu número no WhatsApp. Por favor, insira o código abaixo para continuar.
            </p>
            <div class="input-container">
                <label for="verification-code">Código de Verificação</label>
                <input type="text" v-model="verificationCode" placeholder="Digite o código" class="input-number-field" maxlength="6" />
            </div>
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
            <p v-if="countdown > 0" class="countdown">
                Você poderá solicitar um novo código em: {{ countdownDisplay }}
            </p>
            <p v-else class="resend-message">
                Este é seu número: {{ customerOTP.formattedMobile }}. Se sim e não recebeu o código, 
                <a href="#" @click.prevent="resendCode">clique aqui</a>.
            </p>
            <div class="button-container">
                <button class="previous-button" @click="goBack">Anterior</button>
                <button class="next-button" @click="goNext">Próximo</button>
            </div>
        </div>
    </div>

    <vue3-snackbar bottom right :duration="4000"></vue3-snackbar>
    <circular-progress-bar ref="loadingDots" />
</template>

<script>

import api from "@/services/api.js";
import { Vue3Snackbar } from "vue3-snackbar";
import CircularProgressBar from '@/components/CircularProgressBar.vue';

export default {
    components: {
        Vue3Snackbar,
        CircularProgressBar
    },
    props: {
        customerOTP: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            verificationCode: '',
            errorMessage: '',
            countdown: 120, // Set countdown in seconds
        };
    },
    computed: {
        countdownDisplay() {
            const minutes = Math.floor(this.countdown / 60);
            const seconds = this.countdown % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
    },
    methods: {
        startCountdown() {
            const timer = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown -= 1;
                } else {
                    clearInterval(timer);
                }
            }, 1000);
        },
        resendCode() {

            this.startLoading();
            this.countdown = 120; // Reset countdown
            this.startCountdown();
            
            let customerRequest = { customer: this.customerOTP };

            api.post(`/otp`, customerRequest)
                .then((response) => {
                    if(response.status != 200){
                        this.errorMessage = 'Erro inesperado! Contacte o administrador';
                    }
                }).catch(() => {
                    this.errorMessage = 'Erro inesperado! Contacte o administrador';
                }).finally(() => {
                    this.stopLoading();
                });

        },
        goBack() {
            this.$emit('update-previous-step');
        },
        goNext() {
            if (this.verificationCode.length === 6) {
                this.validateOTPCode();
            } else {
                this.errorMessage = 'Por favor, insira o código completo.';
            }
        },
        startLoading() {
            this.$refs.loadingDots.show();
        },
        stopLoading() {
            this.$refs.loadingDots.hide();
        },
        validateOTPCode(){

            this.startLoading();
            let verificationCode = this.verificationCode;
            let mobile = this.customerOTP.countryCode + '' + this.customerOTP.mobile;

            api.get(`/otp/${verificationCode}/customer/mobile/${mobile}`)
                .then((response) => {
                    if(response.status == 200){
                        this.stopLoading();
                        this.$emit('update-next-step');
                    }
                }).catch((error) => {
                    if(error.response.status == 404){
                        this.errorMessage = 'Código não encontrado!';
                    } else if(error.response.status == 401){
                        this.errorMessage = 'Código expirado!';
                    } else {
                        this.errorMessage = 'Erro inesperado! Contacte o administrador';
                    }
                    this.stopLoading();
                });
        }
    },
    mounted() {
        this.startCountdown();
    }
};
</script>

<style scoped>
.mobile-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 700px;
    max-width: 90%;
    background-color: #f0f2f5;
}

.company-image {
    max-width: 100px;
    margin-bottom: 20px;
}

.mobile-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.instruction {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.input-container {
    width: 100%;
    margin-bottom: 15px;
}

label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}

.input-number-field {
    width: 96%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.error {
    color: #ff4d4f;
    background: #fff3f4;
    padding: 10px;
    border: 1px solid #ff4d4f;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 96%;
    text-align: center;
    font-size: 0.9em;
}

.countdown {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.resend-message {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.resend-message a {
    color: #4CAF50;
    text-decoration: underline;
    cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.previous-button,
.next-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.previous-button:hover,
.next-button:hover {
    background-color: #45a049;
}

div {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f2f5;
}
</style>
