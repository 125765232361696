<template>
  <router-view path="$router.key"></router-view>
</template>
<script>
export default {
  name: "App",
};
</script>

<style></style>
