<template>

    <body>
        <div class="reset-password-container">
            <!-- Logo at the top -->
            <img src="@/assets/logo_seu-idioma.png" alt="Company Logo" class="company-logo" />

            <!-- Form for password reset request -->
            <div class="reset-password-form">
                <h2>Recuperar Senha</h2>
                <form @submit.prevent="requestReset">
                    <div class="form-group">
                        <label for="email">Email *</label>
                        <input type="email" id="email" v-model="email" placeholder="Digite seu email" required />
                    </div>
                    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
                    <button type="submit" class="reset-button">Reiniciar Senha</button>
                </form>
            </div>
        </div>

        <reset-password-modal-component :isVisible="isModalVisible" :message="successMessage"></reset-password-modal-component>
        <vue3-snackbar bottom right :duration="4000"></vue3-snackbar>
        <circular-progress-bar ref="loadingDots" />
    </body>
</template>

<script>
import api from "@/services/api.js";
import { Vue3Snackbar } from "vue3-snackbar";
import CircularProgressBar from '@/components/CircularProgressBar.vue';
import ResetPasswordModalComponent from '@/components/ResetPasswordModalComponent.vue';

export default {
    components: {
        Vue3Snackbar,
        CircularProgressBar,
        ResetPasswordModalComponent
    },
    data() {
        return {
            email: "",
            errorMessage: "",
            successMessage: "Um email foi enviado para você definir uma nova senha. Verifique sua caixa de entrada",
            isModalVisible: false
        };
    },
    methods: {
        requestReset() {
            if (!this.email) {
                this.errorMessage = "Por favor, digite seu email.";
                return;
            }

            this.startLoading();

            api.post(`users/reset`, { user: { email: this.email } })
                .then(() => {
                    this.showSuccessModal();
                    this.errorMessage = "";
                })
                .catch((exception) => {
                    this.handleError(exception);
                })
                .finally(() => {
                    this.stopLoading();
                });
        },
        handleError(exception) {
            let responseMessage = "Erro interno! Contate o administrador.";
            if (exception.response) {
                if (exception.response.status == 404) {
                    responseMessage = "Email não encontrado!";
                }
            }
            this.errorMessage = responseMessage;
            this.stopLoading();
        },
        showSuccessModal() {
            this.isModalVisible = true;
            this.stopLoading();
        },
        startLoading() {
            this.$refs.loadingDots.show();
        },
        stopLoading() {
            this.$refs.loadingDots.hide();
        }
    }
};
</script>

<style scoped>
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #f0f2f5;
}

.reset-password-container {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    text-align: center;
}

.company-logo {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}

.reset-password-form {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.reset-password-form h2 {
    margin-bottom: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}

.form-group label {
    margin-bottom: 8px;
    font-size: 1em;
}

.form-group input,
.error-message {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    line-height: 1.2;
}

.reset-button {
    width: 100%;
    height: 40px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.reset-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: #ff4d4f;
    background: #fff3f4;
    padding: 10px;
    border: 1px solid #ff4d4f;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 0.9em;
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
    .reset-password-container {
        padding: 20px;
        width: 95%;
    }

    .reset-password-form {
        padding: 20px;
    }

    .company-logo {
        width: 60px;
        height: 60px;
        margin-bottom: 15px;
    }

    .form-group input,
    .error-message,
    .reset-button {
        height: 36px;
        font-size: 13px;
    }

    .reset-button {
        height: 36px;
    }

    .reset-password-form h2 {
        font-size: 1.5em;
    }
}

@media (max-width: 480px) {
    .reset-password-container {
        padding: 10px;
    }

    .reset-password-form {
        padding: 10px;
    }

    .company-logo {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
    }

    .form-group input,
    .error-message,
    .reset-button {
        height: 32px;
        font-size: 12px;
    }

    .reset-button {
        height: 32px;
    }

    .reset-password-form h2 {
        font-size: 1.2em;
    }
}
</style>
