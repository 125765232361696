<template>
  <div v-if="showModal" class="modal-overlay">
    <div class="modal">
      <!-- Modal Header with Title -->
      <div class="modal-header">
        <span class="modal-title">Selecione suas Preferências</span>
        <button @click="closeModal" class="close-modal-button">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6M6 6L18 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <!-- Category Title -->
      <div class="category-title">{{ currentCategory.category }}</div>

      <div class="modal-body">
        <!-- Search Bar -->
        <div class="search-bar">
          <input 
            type="text" 
            v-model="searchQuery" 
            placeholder="Pesquisar preferências..." 
            @input="filterPreferences"
          />
        </div>
        
        <!-- Preferences Grid -->
        <div v-if="filteredPreferences.length > 0">
          <div class="preferences-grid">
            <div 
              v-for="preference in filteredPreferences" 
              :key="preference.id" 
              class="preference-item" 
              :class="{ 'selected': isSelected(preference) }"
              @click="togglePreference(preference)"
            >
              <span>{{ preference.preference }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Breadcrumb Navigation and Footer -->
      <div class="modal-footer">
        <div class="breadcrumb">
          <span 
            v-for="(category, index) in filteredCategories" 
            :key="category.category"
            :class="{ 'bullet': true, 'active': currentStep === index, 'completed': currentStep > index }"
          ></span>
        </div>
        <div class="footer-buttons">
          <button @click="prevStep" :disabled="currentStep === 0" class="nav-button">Anterior</button>
          <button @click="nextStep" :disabled="currentStep === filteredCategories.length - 1"
            class="nav-button">Próximo</button>
          <button @click="savePreferences" class="salvar-button" :disabled="isSaveButtonDisabled">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    preferencesCategories: {
      type: Array,
      default: () => []
    },
    selectedPreferences: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentStep: 0,
      internalSelectedPreferences: [],
      searchQuery: ''
    };
  },
  computed: {
    filteredCategories() {
      return this.preferencesCategories.filter(category => category.preferences.length > 0);
    },
    currentCategory() {
      return this.filteredCategories[this.currentStep] || {};
    },
    filteredPreferences() {
      return this.currentCategory.preferences.filter(preference =>
        preference.preference.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    isSaveButtonDisabled() {
      return this.internalSelectedPreferences.length === 0;
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    savePreferences() {
      this.$emit('save', this.internalSelectedPreferences);
      this.closeModal();
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
        this.updateSelectedPreferences();
      }
    },
    nextStep() {
      if (this.currentStep < this.filteredCategories.length - 1) {
        this.currentStep++;
        this.updateSelectedPreferences();
      }
    },
    updateSelectedPreferences() {
      console.log("internalSelectedPreferences", this.internalSelectedPreferences);
    },
    togglePreference(preference) {
      const index = this.internalSelectedPreferences.findIndex(p => p.id === preference.id);
      if (index === -1) {
        this.internalSelectedPreferences.push(preference);
      } else {
        this.internalSelectedPreferences.splice(index, 1);
      }
    },
    isSelected(preference) {
      return this.internalSelectedPreferences.some(p => p.id === preference.id);
    }
  },
  watch: {
    selectedPreferences: {
      handler(newSelectedPreferences) {
        this.internalSelectedPreferences = newSelectedPreferences.slice();
      },
      immediate: true
    }
  },
  mounted() {
    this.internalSelectedPreferences = this.selectedPreferences.slice();
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  width: 650px; /* Fixed width */
  max-height: 80vh; /* Max height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 1.2em;
  color: #555;
}

.close-modal-button {
  border: none;
  background: none;
  cursor: pointer;
}

.category-title {
  font-size: 2em;
  font-family: 'Arial', sans-serif;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.modal-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.search-bar {
  margin-bottom: 15px;
}

.search-bar input {
  width: 98%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.preferences-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3-column layout by default */
  gap: 10px;
  border-radius: 15px;
  border: 3px solid #b3b3b3; /* Fixed border line */
  padding: 10px;
  width: 97%; /* Ensure width matches search bar */
  height: 200px; /* Fixed height */
  overflow: auto; /* Allow scroll if preferences exceed height */
}

@media (max-width: 768px) {
  .preferences-grid {
    grid-template-columns: 1fr; /* Switch to 1 column on small screens */
    grid-template-rows: repeat(2, 1fr); /* 2 rows */
  }
}

.preference-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 44px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  background-color: #f0f0f0;
}

.preference-item:hover {
  background-color: #e0e0e0;
}

.preference-item.selected {
  background-color: #28a745;
  color: #fff;
}

.modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.breadcrumb {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
}

.bullet.active {
  background-color: #28a745;
}

.bullet.completed {
  background-color: #007bff;
}

.nav-button {
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s;
}

.nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.nav-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.salvar-button {
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  background-color: #28a745;
  transition: background-color 0.3s;
}

.salvar-button:hover {
  background-color: #218838;
}

.salvar-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
