<template>
    <div class="page-container">
        <div class="change-password-container">
            <!-- Logo at the top -->
            <img src="@/assets/logo_seu-idioma.png" alt="Company Logo" class="company-logo" />

            <!-- Form for password change -->
            <div class="change-password-form">
                <h2>Alterar Senha</h2>
                <form @submit.prevent="changePassword">
                    <div class="form-group">
                        <label for="password">Nova Senha *</label>
                        <input type="password" id="password" v-model="password" placeholder="Digite sua nova senha"
                            required />
                    </div>
                    <div class="form-group">
                        <label for="confirmPassword">Confirmar Nova Senha *</label>
                        <input type="password" id="confirmPassword" v-model="confirmPassword"
                            placeholder="Confirme sua nova senha" required />
                    </div>
                    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
                    <button type="submit" class="change-button">Alterar Senha</button>
                </form>
            </div>
        </div>
    </div>

    <reset-password-modal-component :isVisible="isModalVisible" :message="successMessage"></reset-password-modal-component>
    <circular-progress-bar ref="loadingDots" />

</template>

<script>
import api from "@/services/api.js";
import { mapMutations } from "vuex";
import CircularProgressBar from '@/components/CircularProgressBar.vue';
import ResetPasswordModalComponent from '@/components/ResetPasswordModalComponent.vue';
export default {
    components: {
        CircularProgressBar,
        ResetPasswordModalComponent
    },
    mounted() {
        this.startLoading()
        let token = this.$route.query.token;

        api.get(`/users/token/${token}`)
            .then(response => {
                this.userId = response.data.user.id;
            })
            .catch((response) => {
                let message = "Erro inesperado! Contacte o administrador";
                if (response.response.status === 401) {
                    message = "Token inválido!";
                } else if (response.response.status === 404) {
                    message = "Token não encontrado!";
                }

                this.errorMessage = message;
            })
            .finally(() => {
                this.stopLoading();
            });

    },
    data() {
        return {
            password: "",
            confirmPassword: "",
            errorMessage: "",
            successMessage: "Sua senha foi redefinida com sucesso!",
            isModalVisible: false
        };
    },
    methods: {
        ...mapMutations(["userId"]),
        changePassword() {
            if (!this.password || !this.confirmPassword) {
                this.errorMessage = "Por favor, preencha todos os campos.";
                return;
            }

            if (this.password !== this.confirmPassword) {
                this.errorMessage = "As senhas não coincidem.";
                return;
            }

            this.startLoading();

            let userId = this.userId;
            let request = { user: { password: this.password } };

            api.put(`/users/${userId}`, request)
                .then(() => {
                    this.password = "";
                    this.confirmPassword = "";
                    this.showSuccessModal();
                })
                .catch(response => {
                    let message = "Erro inesperado! Contacte o administrador";
                    if(response.response.status == 404){
                        message = "Usuário não encontrado!"
                    }

                    this.errorMessage = message;
                })
                .finally(() => {
                    this.stopLoading();
                });


        },
        startLoading() {
            this.$refs.loadingDots.show();
        },
        stopLoading() {
            this.$refs.loadingDots.hide();
        },
        showSuccessModal() {
            this.isModalVisible = true;
            this.stopLoading();
        }
    },
    computed: {
        ...mapMutations(["getUserId"])
    }
};
</script>

<style scoped>
div {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.change-password-container {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    text-align: center;
}

.company-logo {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}

.change-password-form {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.change-password-form h2 {
    margin-bottom: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form-group label {
    margin-bottom: 8px;
    font-size: 1em;
}

.form-group input {
    height: 40px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.change-button {
    width: 100%;
    height: 40px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.change-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: #ff4d4f;
    background: #fff3f4;
    padding: 10px;
    border: 1px solid #ff4d4f;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 0.9em;
    text-align: center;
}
</style>
