<template>

  <body>
    <div class="modal-overlay" v-if="isVisible">
      <div class="modal-content">
        <h2>{{ title }}</h2>
        <p>{{ message }}</p>
        <button class="ok-button" @click="handleOk">OK</button>
      </div>
    </div>
  </body>
</template>

<script>
import router from "@/routes/routes.js";

export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: "Sucesso!"
    };
  },
  methods: {
    handleOk() {
      router.push({ path: "/login" });
    }
  }
};
</script>

<style scoped>
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f2f5;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.ok-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.ok-button:hover {
  background-color: #0056b3;
}
</style>