import { createRouter, createWebHistory } from "vue-router";
import LoginComponent from "@/components/LoginComponent.vue";
import ProfileComponent from "@/components/ProfileComponent.vue";
import RegistrationComponent from "@/components/RegistrationComponent.vue";
import InitialFlowComponent from "@/components/InitialFlowComponent.vue";
import ResetPasswordComponent from "@/components/ResetPasswordComponent.vue";
import ChangePasswordComponent from "@/components/ChangePasswordComponent.vue";

const routes = [
  {
    path: "/",
    name: "LoginInitial",
    component: LoginComponent,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginComponent,
  },
  {
    path: "/profile",
    name: "Sua página",
    component: ProfileComponent,
  },
  {
    path: "/registration",
    name: "Registro",
    component: RegistrationComponent,
  },
  {
    path: "/wizard",
    name: "Início",
    component: InitialFlowComponent,
  },
  {
    path: "/reset-password",
    name: "Recupere sua senha",
    component: ResetPasswordComponent,
  },
  {
    path: "/change-password",
    name: "Alterar sua senha",
    component: ChangePasswordComponent,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
