<template>
    <div class="password-box">
        <div class="password-container">
            <img src="@/assets/logo_seu-idioma.png" alt="Company Logo" class="company-image" />
            <h2 class="password-title">Defina a sua senha</h2>
            <div class="input-container">
                <label for="password">Senha</label>
                <input type="password" v-model="password" @input="validatePassword" placeholder="Digite sua senha" />
            </div>
            <div class="input-container">
                <label for="confirm-password">Confirmar Senha</label>
                <input type="password" v-model="confirmPassword" @input="validatePassword"
                    placeholder="Confirme sua senha" />
            </div>
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
            <div class="button-container">
                <button class="previous-button" @click="goBack">Anterior</button>
                <button class="next-button" @click="goToNext">Próximo</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            password: '',
            confirmPassword: '',
            errorMessage: '',
        };
    },
    methods: {
        validatePassword() {
            
            if (this.password !== this.confirmPassword) {
                this.errorMessage = 'As senhas não coincidem';
            } else {
                this.errorMessage = '';
            }
        },
        submitPassword() {

            if(!this.password){
                this.errorMessage = 'Por favor, insira uma senha.';
                return false;
            }

            if (this.password === this.confirmPassword) {
                this.$emit('password-changed', this.password);
                return true;
            }
        },
        goBack() {
            this.$emit('update-previous-step');
        },
        goToNext() {
            if(this.submitPassword()){
                this.$emit('update-next-step');
            }
        }

    }
};
</script>

<style scoped>
.password-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
}

.password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 700px;
    height: 630px;
    max-height: 80%;
    max-width: 90%;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
}

.company-image {
    max-width: 100px;
    margin-bottom: 20px;
}

.password-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.input-container {
    width: 100%;
    margin-bottom: 15px;
}

label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}

input {
    width: 90%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.error {
    color: #ff4d4f;
    background: #fff3f4;
    padding: 10px;
    border: 1px solid #ff4d4f;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 96%;
    text-align: center;
    font-size: 0.9em;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.previous-button,
.next-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.previous-button:hover,
.next-button:hover {
    background-color: #45a049;
}

div {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f2f5;
}
</style>