<template>
    <div v-if="isVisible" class="modal-overlay">
      <div class="modal-content">
        <div class="loading-container">
          <div class="dot" v-for="dot in 3" :key="dot" :class="['dot', { 'bounce': isBouncing }]"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isVisible: false,
        isBouncing: false,
      };
    },
    methods: {
      show() {
        this.isVisible = true;
        this.isBouncing = true;
      },
      hide() {
        this.isVisible = false;
        this.isBouncing = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dot {
    width: 15px;
    height: 15px;
    margin: 0 5px;
    background-color: #4caf50;
    border-radius: 50%;
    animation: bounce 0.6s infinite alternate;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-15px);
    }
  }
  </style>
  