<template>

    <body>
        <div class="register-container">
            <!-- Logo at the top -->
            <img src="@/assets/logo_seu-idioma.png" alt="Company Logo" class="company-logo" />

            <!-- Form for registration -->
            <div class="register-form">
                <h2>Registrar</h2>
                <form @submit.prevent="register">
                    <div class="form-group">
                        <label for="email">Email *</label>
                        <input type="email" id="email" v-model="customer.email" placeholder="Digite seu email"
                            required />
                    </div>
                    <div class="form-group">
                        <label for="language">Idioma que deseja praticar *</label>
                        <select id="language" v-model="customer.languageId" required>
                            <option value="" disabled selected>Selecione o idioma</option>
                            <option v-for="language in languages" :key="language.id" :value="language.id">{{
                                language.name }}</option>

                        </select>
                    </div>
                    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
                    <button type="submit" class="register-button" v-on:click="save">Registrar</button>
                </form>
            </div>
        </div>
        <vue3-snackbar bottom right :duration="4000"></vue3-snackbar>
        <email-verification-modal :isVisible="isModalVisible" @close="hideModal"
            @ok="redirectToLogin"></email-verification-modal>
        <circular-progress-bar ref="loadingDots" />
    </body>
</template>

<script>

import api from "@/services/api.js";
import { Vue3Snackbar } from "vue3-snackbar";
import router from "@/routes/routes.js";
import EmailVerificationModal from "@/components/EmailVerificationModal";
import CircularProgressBar from '@/components/CircularProgressBar.vue';

export default {
    components: {
        Vue3Snackbar,
        EmailVerificationModal,
        CircularProgressBar
    },
    data() {
        return {
            isModalVisible: false,
            customer: {
                email: "",
                languageId: ""
            },
            languages: [],
            errorMessage: ""

        };
    },
    methods: {
        save() {
            if (!this.customer.email || !this.customer.languageId) {
                this.errorMessage = "Por favor, preencha todos os campos.";
                return;
            }

            this.startLoading();

            api.post(`/customers`, { customer: this.customer })
                .then(() => {
                    this.successMessage();
                }).catch((exception) => {
                    let responseMessage = "Erro interno! Contacte o administrador.";
                    if (exception.response) {
                        if (exception.response.status == 404) {
                            responseMessage = "Email não encontrado!";

                        } else if (exception.response.status == 409) {
                            responseMessage = "Este email já está cadastrado!";
                        }
                    }
                    this.errorMessage = responseMessage;
                });

        },
        successMessage() {
            this.$snackbar.add({
                type: 'success',
                text: 'Salvo!'
            });
            this.stopLoading();
            this.isModalVisible = true;


        },
        hideModal() {
            this.isModalVisible = false;
        },
        redirectToLogin() {
            this.isModalVisible = false;
            setTimeout(() => { router.push({ path: "login" }); }, 1000);
        },
        startLoading() {
            this.$refs.loadingDots.show();
        },
        stopLoading(){
            this.$refs.loadingDots.hide();
        }
    },
    mounted() {
        api.get(`/languages`).then(response => {
            this.languages = response.data.languages;
        })
    }
};
</script>

<style scoped>
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #f0f2f5;
}

.register-container {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    text-align: center;
}

.company-logo {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
}

.register-form {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.register-form h2 {
    margin-bottom: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}

.form-group label {
    margin-bottom: 8px;
    font-size: 1em;
}

.form-group input,
.error-message,
.form-group select {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    line-height: 1.2;
}

.register-button {
    width: 100%;
    height: 40px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.register-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: #ff4d4f;
    background: #fff3f4;
    padding: 10px;
    border: 1px solid #ff4d4f;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 0.9em;
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
    .register-container {
        padding: 20px;
        width: 95%;
    }

    .register-form {
        padding: 20px;
    }

    .company-logo {
        width: 60px;
        height: 60px;
        margin-bottom: 15px;
    }

    .form-group input,
    .form-group select,
    .error-message,
    .register-button {
        height: 36px;
        font-size: 13px;
    }

    .register-button {
        height: 36px;
    }

    .register-form h2 {
        font-size: 1.5em;
    }
}

@media (max-width: 480px) {
    .register-container {
        padding: 10px;
    }

    .register-form {
        padding: 10px;
    }

    .company-logo {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
    }

    .form-group input,
    .form-group select,
    .error-message,
    .register-button {
        height: 32px;
        font-size: 12px;
    }

    .register-button {
        height: 32px;
    }

    .register-form h2 {
        font-size: 1.2em;
    }
}
</style>