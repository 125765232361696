<template>
    <div class="welcome-box">
        <div class="welcome-container">
            <img src="@/assets/logo_seu-idioma.png" alt="Company Logo" class="company-image" />
            <h1 class="welcome-message">Bem-vindo à nossa plataforma! :D</h1>
            <button class="next-button" @click="goToNext">Próximo</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {
        goToNext() {
            this.$emit('update-next-step');
        }
    }
};
</script>

<style scoped>
.welcome-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
}

.welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 700px;
    height: 630px;
    max-height: 80%;
    max-width: 90%;
    justify-content: center; 
    align-items: center;
}

.company-image {
    max-width: 100px;
    margin-bottom: 20px;
}

.welcome-message {
    font-size: 24px;
    margin-bottom: 20px;
}

.next-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.next-button:hover {
    background-color: #45a049;
}

div {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f2f5;
}
</style>