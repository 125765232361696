<template>

  <body>
    <div class="container">
      <h1>Login</h1>
      <form>
        <div :class="['input-group', { 'has-error': errors.username }]">
          <label for="email">Email:</label>
          <input type="email" id="email" placeholder="Email *" v-model="username" />
        </div>
        <div :class="['input-group', { 'has-error': errors.password }]">
          <label for="senha">Senha:</label>
          <div class="password-wrapper">
            <input :type="passwordVisible ? 'text' : 'password'" id="senha" placeholder="Senha *" v-model="password"
              class="password-input" />
            <i :class="[
              'password-icon',
              passwordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'
            ]" @click="togglePasswordVisibility"></i>
          </div>
        </div>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        <button type="button" class="login-button" v-on:click="submit">
          Login
        </button>
        <a class="forgot-password"><router-link :to="{ path: '/reset-password' }">Esqueceu a senha?</router-link></a>
        <!-- <a class="forgot-password">Esqueceu a senha?</a> -->
      </form>
    </div>
  </body>
</template>

<script>
import api from "@/services/api.js";
import router from "@/routes/routes.js";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      username: "",
      password: "",
      passwordVisible: false,
      errorMessage: "",
      errors: {
        username: false,
        password: false
      }
    };
  },
  methods: {
    ...mapMutations(["setUserId", "setToken"]),
    validateFields() {
      this.errors.username = !this.username;
      this.errors.password = !this.password;

      return !this.errors.username && !this.errors.password;
    },
    submit() {
      if (!this.validateFields()) {
        this.errorMessage = "Por favor preencha todos os campos requeridos.";
        return;
      }

      api.post("/auth/signin", {
        user: {
          email: this.username,
          password: this.password
        }
      })
        .then((response) => {
          this.errorMessage = "";

          let authenticatedUser = response.data["authenticated-user"];

          this.setUserId(authenticatedUser.id);
          this.setToken(authenticatedUser.token);

          let next = { path: "profile" };
          if (authenticatedUser.fistLogin) {
            next = { path: "wizard" };
          }
          router.push(next);

        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.errorMessage = "Email ou senha estão incorretos!";
          } else {
            this.errorMessage = "Erro interno! Contacte o administrador.";
          }
        });
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    }
  }
};
</script>

<style scoped>
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f2f5;
}

.container {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  text-align: center;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.input-group label {
  margin-bottom: 8px;
  text-align: left;
  font-size: 1em;
  color: #555;
}

.input-group input {
  padding: 12px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.input-group.has-error input {
  border-color: red;
}

.input-group input:focus {
  border-color: #007bff;
  outline: none;
}

.login-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 15px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  transition: background-color 0.3s, transform 0.3s;
}

.login-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.forgot-password {
  margin-top: 20px;
  font-size: 1em;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

.forgot-password:hover {
  color: #0056b3;
}

@media (max-width: 600px) {
  .container {
    padding: 20px;
  }

  h1 {
    font-size: 1.5em;
  }

  .login-button {
    font-size: 0.9em;
    padding: 10px 15px;
  }

  .forgot-password {
    font-size: 0.9em;
  }
}

.error-message {
  color: #ff4d4f;
  background: #fff3f4;
  padding: 10px;
  border: 1px solid #ff4d4f;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 0.9em;
}

.password-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input {
  padding: 12px;
  padding-right: 40px;
  /* Increased space for the icon */
  width: 100%;
  /* Ensures the input takes full width of its container */
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.password-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-size: 1.2em;
  color: #007bff;
}

.password-icon:hover {
  color: #0056b3;
}

a {
  text-decoration: none;
}
</style>
