import axios from "axios";

const api = axios.create({
  baseURL: "https://idiomatech-backend-93b79fb2a092.herokuapp.com/api",
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  withCredentials: false
});

export default api;
