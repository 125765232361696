<template>
    <div v-if="firstLogin" class="card">
      <h2>Complete seu cadastro</h2>
      <p>Para acessar o chat AI, você precisa finalizar o seu cadastro.</p>
      <p>Preencha todos os campos e defina uma senha</p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      firstLogin: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      redirectToRegistration() {
        this.$router.push('/registration');
      }
    }
  };
  </script>
  
  <style scoped>
  .card {
    background-color: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .card h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .card p {
    font-size: 1em;
    margin-bottom: 20px;
  }
  
  .action-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: #0056b3;
  }
  </style>
  