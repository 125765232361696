<template>
    <div class="mobile-box">
        <div class="mobile-container">
            <img src="@/assets/logo_seu-idioma.png" alt="Company Logo" class="company-image" />
            <h2 class="mobile-title">Confirme suas informações</h2>
            <div class="input-container">
                <label for="nome">Nome</label>
                <input type="text" v-model="customer.name" placeholder="Digite seu nome" class="input-field" />
            </div>
            <div class="input-container">
                <label for="sobrenome">Sobrenome</label>
                <input type="text" v-model="customer.lastname" placeholder="Digite seu sobrenome"
                    class="input-field" />
            </div>
            <div class="input-container">
                <label for="email">Email</label>
                <input type="email" v-model="customer.user.email" placeholder="Digite seu email"
                    class="input-field" />
            </div>
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
            <div class="button-container">
                <button class="previous-button" @click="goBack">Anterior</button>
                <button class="next-button" @click="goNext">Finalizar</button>
            </div>
        </div>

        <circular-progress-bar ref="loadingDots" />

    </div>
</template>

<script>

import { mapGetters } from "vuex";
import api from "@/services/api.js";
import CircularProgressBar from '@/components/CircularProgressBar.vue';

export default {

    components: {
        CircularProgressBar
    },

    mounted() {

        this.startLoading();

        let token = this.getToken;
        let userId = this.getUserId;

        api.get(`/customers/users/${userId}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            let customerFetched = response.data.customer;
            this.customer.id = customerFetched.id;
            this.customer.name = customerFetched.name;
            this.customer.lastname = customerFetched.lastname;
            this.customer.user.email = customerFetched.user.email;
            this.stopLoading();
        });
    },
    data() {
        return {
            customer: {
                id: "",
                name: "",
                lastname: "",
                user:{
                    email: ""
                }
            },
            errorMessage: '',
        };
    },
    props: {
        fetchedCustomer: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters(["getToken", "getUserId"])
    },
    methods: {
        validateFields() {
            if (!this.customer.name) {
                this.errorMessage = 'Por favor, informe o seu nome.';
                return false;
            } else if (!this.customer.lastname) {
                this.errorMessage = 'Por favor, informe o seu sobrenome.';
                return false;
            } else if (!this.customer.user.email) {
                this.errorMessage = 'Por favor, informe o seu melhor email.';
                return false;
            }

            this.errorMessage = '';
            return true;
        },
        updateCustomerModel(customerModel) {
            this.customer = customerModel;
        },
        goBack() {
            this.$emit('update-previous-step');
        },
        goNext() {
            if (this.validateFields()) {
                this.$emit('customer-data', this.customer);
                this.$emit('finish-wizard');
            }
        },
        startLoading() {
            this.$refs.loadingDots.show();
        },
        stopLoading(){
            this.$refs.loadingDots.hide();
        }
    }
};
</script>

<style scoped>
.mobile-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 700px;
    height: 630px;
    max-height: 80%;
    max-width: 90%;
    justify-content: center;
    background-color: #f0f2f5;
}

.company-image {
    max-width: 100px;
    margin-bottom: 20px;
}

.mobile-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.input-container {
    width: 100%;
    margin-bottom: 15px;
}

label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}

.input-field {
    width: 95%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.error {
    color: #ff4d4f;
    background: #fff3f4;
    padding: 10px;
    border: 1px solid #ff4d4f;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 96%;
    text-align: center;
    font-size: 0.9em;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.previous-button,
.next-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.previous-button:hover,
.next-button:hover {
    background-color: #45a049;
}

div {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f2f5;
}
</style>
