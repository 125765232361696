<template>
    <div class="congrats-container">
        <img src="@/assets/logo_seu-idioma.png" alt="Company Logo" class="company-logo">
        <h1>Parabéns por finalizar seu cadastro!</h1>
        <p>Agora você pode usar a IA.</p>
        <button @click="goToProfile" class="ai-button" >
            <img :src="aiButtonIcon" alt="Go to AI" />
        </button>
    </div>
</template>

<script>

import aiButtonIcon from '@/assets/WhatsAppButtonGreenLarge.svg';
import router from "@/routes/routes.js";

export default {
    data(){
        return{
            aiButtonIcon,
            whatsappLink: "https://wa.me/351939675005",
        }
    },
    methods: {
        goToProfile() {
            window.open(this.whatsappLink, '_blank').focus();
            router.push({ path: "profile" });
        }
    }
}
</script>

<style scoped>
.congrats-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.company-logo {
    width: 100px;
    /* Adjust size */
    margin-bottom: 20px;
}

.ai-button {
    margin-top: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

div {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f2f5;
}
</style>