import { createApp } from "vue";
import App from "./App.vue";
import router from "@/routes/routes.js";
import store from "@/store/main.js";
import { SnackbarService } from "vue3-snackbar";
import "vue3-snackbar/styles";
import '@fortawesome/fontawesome-free/css/all.css';

createApp(App)
    .use(router)
    .use(store)
    .use(SnackbarService)
    .mount("#app");
