<template>

  <body>
    <div class="container">

      <div class="header">
        <img src="@/assets/logo_seu-idioma.png" alt="Company Logo" class="company-logo" />
        <div class="user-actions">
          <button @click="logout" class="logout-button">Logout</button>
        </div>
      </div>
      <FirstLoginCard :firstLogin="customer.user.firstLogin" />

      <h1>Olá</h1>
      <form>
        <div class="name-container">
          <div :class="['input-group', { 'has-error': errors.name }]">
            <label for="nome">Nome:</label>
            <input type="text" id="nome" placeholder="Nome *" v-model="customer.name">
          </div>
          <div :class="['input-group', { 'has-error': errors.lastname }]">
            <label for="sobrenome">Sobrenome:</label>
            <input type="text" id="sobrenome" placeholder="Sobrenome *" v-model="customer.lastname">
          </div>
        </div>

        <div :class="['input-group', { 'has-error': errors.email }]">
          <label for="email">Email:</label>
          <input type="email" id="email" placeholder="Email *" v-model="customer.user.email">
        </div>

        <div class="password-container">
          <div :class="['input-group', { 'has-error': errors.password || customer.user.firstLogin }]">
            <label for="senha">Senha:</label>
            <input type="password" id="senha" placeholder="Senha *" v-model="customer.user.password">
          </div>
          <div :class="['input-group', { 'has-error': errors.confirmPassword || customer.user.firstLogin }]">
            <label for="confirmar-senha">Confirme a senha:</label>
            <input type="password" id="confirmar-senha" placeholder="Confirme a senha *"
              v-model="customer.user.confirmPassword">
          </div>
        </div>

        <div class="mobile-container">
          <div :class="['input-group', { 'has-error': errors.countryCode }]">
            <label for="countryCode">Código do País:</label>
            <select id="countryCode" v-model="customer.countryCode" @change="updateMask">
              <option value="55">Brazil (+55)</option>
              <option value="351">Portugal (+351)</option>
            </select>
          </div>
          <div :class="['input-group', { 'has-error': errors.mobile }]">
            <label for="celular">Celular:</label>
            <input type="text" id="celular" placeholder="Celular *" v-model="customer.mobile" v-mask="mobileMask">
          </div>
        </div>

        <div class="input-group">
          <label for="language">Idioma:</label>
          <input type="text" id="language" placeholder="Idioma" disabled v-model="customer.language.name">
        </div>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

        <p>Queremos saber quem é você?</p>
        <button @click="openModal" class="open-modal-button" type="button">Escolher Preferências</button>

        <div v-if="customer.preferences.length" class="selected-preferences">
          <h2>Preferências Selecionadas:</h2>
          <div class="preference-badges">
            <span v-for="pref in customer.preferences" :key="pref.id" class="badge">{{ pref.preference }}</span>
          </div>
        </div>

        <button type="button" class="salvar-button" v-on:click="save">Salvar</button>
        <a class="whatsapp-button" :href="whatsappLink" v-if="showChatAIButton" target="_blank">
          Acessar AI
        </a>
      </form>

      <preferences-modal :showModal="isPreferencesModalVisible" :preferencesCategories="fetchedPreferences"
        :selectedPreferences="customer.preferences" @close="closeModal" @save="handlePreferencesSave">
      </preferences-modal>


      <vue3-snackbar bottom right :duration="4000"></vue3-snackbar>

    </div>
  </body>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/services/api.js";
import { mask } from 'vue-the-mask';
import { Vue3Snackbar } from "vue3-snackbar";
import PreferencesModal from '@/components/PreferencesComponent.vue';
import FirstLoginCard from '@/components/FirstLoginCardComponent.vue';

export default {
  components: {
    PreferencesModal,
    Vue3Snackbar,
    FirstLoginCard
  },
  directives: { mask },
  data() {
    return {
      mobileMask: ['(##) #####-####'],
      customer: {
        id: "",
        name: "",
        lastname: "",
        mobile: "",
        language: "",
        countryCode: "55",
        user: {
          email: "",
          password: "",
          confirmPassword: "",
          firstLogin: true
        },
        preferences: []
      },
      errorMessage: "",
      errors: {
        name: false,
        lastname: false,
        password: false,
        confirmPassword: false,
        mobile: false,
        countryCode: false,
        email: false,
        preferences: false 
      },
      isPreferencesModalVisible: false,
      fetchedPreferences: [],
      whatsappLink: "https://wa.me/351939675005",
      isAlreadySaved: false
    };
  },
  methods: {
    validateFields() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const brazilPhonePattern = /^\(\d{2}\) \d{4,5}-\d{4}$/;
      const portugalPhonePattern = /^\d{3} \d{3} \d{3}$/;

      if (this.customer == undefined) {
        return false;
      }

      this.errors.name = !this.customer.name;
      this.errors.lastname = !this.customer.lastname;
      this.errors.email = !this.customer.user.email || !emailPattern.test(this.customer.user.email);

      if (this.customer.user.firstLogin) {
        this.errors.password = !this.customer.user.password;
      }
      this.errors.confirmPassword = this.customer.user.password !== this.customer.user.confirmPassword;

      this.errors.countryCode = !this.customer.countryCode;

      if (this.customer.countryCode != undefined) {
        if (this.customer.countryCode === 55) {
          this.errors.mobile = !this.customer.mobile || !brazilPhonePattern.test(this.customer.mobile);
        } else if (this.customer.countryCode === 351) {
          this.errors.mobile = !this.customer.mobile || !portugalPhonePattern.test(this.customer.mobile);
        }
      }

      this.errors.preferences = !this.customer.preferences || this.customer.preferences.length == 0;

      return !Object.values(this.errors).includes(true);
    },
    save() {
      if (!this.validateFields()) {
        this.errorMessage = "Por favor, preencha todos os campos corretamente.";
        return;
      }

      this.customer.mobile = this.customer.mobile.replaceAll(/\s|\+|\(|\)|-/g, "");
      this.customer.user.firstLogin = false;

      let customerId = this.customer.id;
      let token = this.getToken;
      api.put(`customers/${customerId}`, { customer: this.customer }, { headers: { 'Authorization': `Bearer ${token}` } }).then(() => {
        this.successMessage();
        this.isAlreadySaved = true;
        this.resetErrorsFlags();

      });
    },
    resetErrorsFlags() {
      this.errors.name = false;
      this.errors.lastname = false;
      this.errors.password = false;
      this.errors.confirmPassword = false;
      this.errors.mobile = false;
      this.errors.countryCode = false;
      this.errors.email = false;
      this.errors.preferences = false;
      this.errorMessage = null;
    },
    updateMask() {
      this.mobileMask = this.customer?.countryCode == "55"
        ? ['(##) ####-####', '(##) #####-####']
        : '### ### ###';
    },
    openModal() {
      let token = this.getToken;
      api.get('/preferences/categories', {
        headers: { 'Authorization': `Bearer ${token}` }
      }).then(response => {
        let preferencesCategories = response.data['preferences-categories'];
        this.fetchedPreferences = preferencesCategories.filter(function (categoryPreference) {
          return !categoryPreference.preferences || categoryPreference.preferences.length > 0;
        });
        this.isPreferencesModalVisible = true;
      });
    },
    closeModal() {
      this.isPreferencesModalVisible = false;
    },
    handlePreferencesSave(preferences) {
      this.customer.preferences = preferences;
    },
    successMessage() {
      this.$snackbar.add({
        type: 'success',
        text: 'Salvo!'
      })
    },
    logout() {
      this.customer = {
        id: "",
        name: "",
        lastname: "",
        mobile: "",
        language: {
          name: ""
        },
        countryCode: "55",
        user: {
          email: "",
          password: "",
          confirmPassword: "",
          firstLogin: true
        },
        preferences: []
      };

      this.redirectToLogin();
    },
    redirectToLogin() {
      this.$router.push('/login');
    }
  },
  mounted() {
    let userId = this.getUserId;
    let token = this.getToken;

    if (!token) {
      this.redirectToLogin();
      return;
    }

    api.get(`/customers/users/${userId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    }).then((response) => {
      this.customer = response.data.customer;
      this.updateMask();
    });
  },
  computed: {
    ...mapGetters(["getUserId", "getToken"]),
    showChatAIButton() {
      if (!this.customer.user.firstLogin) {
        return true;
      }
      return this.isLoginAlreadySaved || !this.customer.user.firstLogin;
    }

  },
};
</script>

<style scoped>
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  background-color: #f0f2f5;
  overflow: hidden;
  /* Prevent body scroll */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.container {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  margin: 20px auto;
  position: relative;
  padding: 20px;
}


h1 {
  font-size: 2em;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name-container,
.password-container,
.mobile-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.input-group label {
  margin-bottom: 8px;
  text-align: left;
  font-size: 1em;
}

.input-group input,
.input-group select {
  padding: 12px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.input-group input:focus,
.input-group select:focus {
  border-color: #007bff;
  outline: none;
}

.input-group.has-error input,
.input-group.has-error select {
  border-color: red;
}

.input-group input[type="password"] {
  margin-bottom: 10px;
}

p {
  margin-top: 20px;
  font-size: 1em;
}

.google-forms-button,
.whatsapp-button,
.salvar-button {
  border: none;
  padding: 15px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  color: #fff;
  transition: background-color 0.3s;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.google-forms-button {
  background-color: #6c757d;
}

.google-forms-button:hover {
  background-color: #5a6268;
}

.whatsapp-button {
  background-color: #25D366;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  border-radius: 50px;
  /* Rounded button */
  font-size: 1em;
  text-decoration: none;
  font-weight: 600;
  margin-top: 20px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.whatsapp-button:hover {
  background-color: #20b851;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add shadow effect */
}

.whatsapp-button::before {
  content: url('https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg');
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.whatsapp-icon {
  margin-right: 8px;
  font-size: 1.2em;
}

/* Salvar Button */
.salvar-button {
  background-color: #28a745;
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.salvar-button:hover {
  background-color: #218838;
  transform: translateY(-2px);
  /* Slight pop-up effect */
}


@media (max-width: 600px) {
  .container {
    padding: 20px;
  }

  h1 {
    font-size: 1.5em;
  }

  .google-forms-button {
    font-size: 0.9em;
    padding: 10px 15px;
  }

  .whatsapp-button,
  .salvar-button {
    font-size: 0.9em;
    padding: 10px 15px;
  }

  .whatsapp-button::before {
    width: 16px;
    height: 16px;
  }

  .name-container,
  .password-container,
  .mobile-container {
    flex-direction: column;
    gap: 0;
  }

  .company-logo {
    width: 150px;
  }

}

.error-message {
  color: #ff4d4f;
  background: #fff3f4;
  padding: 10px;
  border: 1px solid #ff4d4f;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 0.9em;
}

.open-modal-button {
  border: none;
  padding: 15px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  color: #fff;
  background-color: #007bff;
  transition: background-color 0.3s;
}

.open-modal-button:hover {
  background-color: #0056b3;
}

.preference-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.preference-badge {
  padding: 8px 12px;
  background-color: #e0f7fa;
  border-radius: 15px;
  color: #007bff;
  font-size: 0.9em;
  font-weight: 500;
  display: inline-block;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.preference-badge:hover {
  background-color: #b2ebf2;
}

.selected-preferences {
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 20px;
}

.selected-preferences h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.selected-preferences p {
  font-size: 1em;
  color: #333;
}

.company-logo {
  width: 150px;
  height: auto;
  display: block;
  margin: 0 auto 20px auto;
}

.logout-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #c82333;
}

.user-actions {
  position: absolute;
  top: 10px;
  right: 10px;
}

.badge {
  display: inline-block;
  padding: 8px;
  margin-right: 5px;
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  font-size: 0.9em;
}
</style>