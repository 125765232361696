<template>
    <div class="preferences-box">
        <div class="preferences-container">
            <!-- Company Logo -->
            <img src="@/assets/logo_seu-idioma.png" alt="Company Logo" class="company-image" />

            <!-- Title and Subtitle -->
            <div class="preferences-title">Selecione suas Preferências</div>
            <div class="preferences-subtitle">Esta página é destinada a personalizar a IA de acordo com as suas
                preferências.</div>

            <!-- Category Title -->
            <div class="category-title">{{ currentCategoryData.category }}</div>


            <div class="search-bar">
                <input type="text" v-model="searchQuery" placeholder="Pesquisar preferências..."
                    @input="filterPreferences" />
            </div>

            <!-- Preferences Grid Wrapper -->
            <div class="preferences-grid-wrapper">
                <!-- Left Arrow -->
                <div @click="prevCategory" class="arrow-button left-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="left-arrow-svg">
                        <polyline points="15 18 9 12 15 6"></polyline>
                    </svg>
                </div>

                <!-- Preferences Grid with Rounded Border -->
                <div class="preferences-grid-container">

                    <div class="preferences-grid">
                        <div class="preferences-items">
                            <div v-for="preference in currentCategoryData.preferences" :key="preference.id"
                                :class="['preference-item', { 'selected': isSelected(preference) }]"
                                @click="togglePreference(preference)">
                                {{ preference.preference }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right Arrow -->
                <div @click="nextCategory" class="arrow-button right-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="right-arrow-svg">
                        <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                </div>
            </div>

            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
            <div class="button-container">
                <button class="previous-button" @click="goBack">Anterior</button>
                <button class="next-button" @click="goToNext">Próximo</button>
            </div>
        </div>

        <circular-progress-bar ref="loadingDots" />

    </div>

</template>

<script>

import { mapGetters } from "vuex";
import api from "@/services/api.js";
import CircularProgressBar from '@/components/CircularProgressBar.vue';

export default {
    mounted() {
        this.startLoading()
        let token = this.getToken;
        api.get('/preferences/categories', {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then(response => {
            let preferencesCategories = response.data['preferences-categories'];
            this.fetchedPreferencesCategories = preferencesCategories.filter(function (categoryPreference) {
                return !categoryPreference.preferences || categoryPreference.preferences.length > 0;
            });
            this.stopLoading();
        });
    },
    components: {
        CircularProgressBar
    },
    props: {
        preferencesCategories: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            currentCategory: 0,
            selectedPreferences: [],
            fetchedPreferencesCategories: [],
            searchQuery: "",
            errorMessage: ""
        };
    },
    computed: {
        currentCategoryData() {
            if (this.fetchedPreferencesCategories.length > 0) {
                let currentPreferenceCategory = this.fetchedPreferencesCategories[this.currentCategory];
                return {
                    category: currentPreferenceCategory.category,
                    preferences: currentPreferenceCategory.preferences.filter(preference => {
                        return preference.preference.toLowerCase().includes(this.searchQuery.toLowerCase())
                    })
                }

            }
            return [];
        },
        ...mapGetters(["getToken"])
    },
    methods: {
        isSelected(preference) {
            return this.selectedPreferences.find(e => e.id === preference.id);
        },
        togglePreference(preference) {
            if (this.isSelected(preference)) {
                this.selectedPreferences = this.selectedPreferences.filter(p => p.id !== preference.id);
            } else {
                this.selectedPreferences.push({ id: preference.id });
            }
        },
        prevCategory() {
            if (this.currentCategory > 0) {
                this.currentCategory--;
            }
        },
        nextCategory() {
            if (this.currentCategory < this.fetchedPreferencesCategories.length - 1) {
                this.currentCategory++;
            }
        },
        submitPreferences() {
            this.errorMessage = '';
            if (!this.selectedPreferences || this.selectedPreferences.length == 0) {
                this.errorMessage = "Por favor, selecione pelo menos uma preferência."
                return false;
            }

            this.$emit('preferences-selected', this.selectedPreferences);
            return true;
        },
        goBack() {
            this.$emit('update-previous-step', 'isPreferencesPage');
        },
        goToNext() {
            if (this.submitPreferences()) {
                this.$emit('update-next-step');
            }
        },
        startLoading() {
            this.$refs.loadingDots.show();
        },
        stopLoading() {
            this.$refs.loadingDots.hide();
        }
    }
};
</script>

<style scoped>
.preferences-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.preferences-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    width: 700px;
    height: 700px;
    background-color: #f0f2f5;
    position: relative;
}

.company-image {
    max-width: 100px;
    margin-bottom: 20px;
}

.preferences-title {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: bold;
}

.preferences-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.category-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.preferences-grid-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    position: relative;
}

.preferences-grid-container {
    border: 2px solid #ccc;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    width: 80%;
}

.preferences-grid {
    width: 100%;
    display: flex;
    justify-content: center;
}

.preferences-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    height: 300px;
    overflow-y: auto;
    width: 100%;
}

.preference-item {
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    flex-grow: 1;
    height: 80px;
}

.preference-item.selected {
    background-color: green;
    color: white;
}

.arrow-button {
    cursor: pointer;
    font-size: 24px;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.left-arrow {
    left: -30px;
}

.right-arrow {
    right: -30px;
}

div {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f2f5;
}

.error {
    color: #ff4d4f;
    background: #fff3f4;
    padding: 10px;
    border: 1px solid #ff4d4f;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 96%;
    text-align: center;
    font-size: 0.9em;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.previous-button,
.next-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.previous-button:hover,
.next-button:hover {
    background-color: #45a049;
}

.arrow-button svg {
    width: 40px;
    height: 40px;
    stroke: #333;
}

.arrow-button:hover svg {
    stroke: #555;
}

.search-bar {
    margin-bottom: 15px;
}

.search-bar input {
    width: 500px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .preferences-container {
        width: 90%; /* Adjust width for smaller screens */
        height: auto; /* Allow height to adjust based on content */
        padding: 10px; /* Reduce padding */
    }

    .preferences-title {
        font-size: 26px; /* Increased title font size */
    }

    .preferences-subtitle {
        font-size: 18px; /* Increased subtitle font size */
    }

    .category-title {
        font-size: 18px; /* Smaller category title */
    }

    .search-bar {
        width: 95%; /* Full width for search bar */
    }

    .search-bar input {
        width: 95%; /* Full width for search input */
    }

    .preferences-items {
        grid-template-columns: repeat(1, 1fr); /* Reduce columns for smaller screens */
    }

    .button-container {
        display: flex; /* Keep button container as flex */
        justify-content: space-between; /* Align buttons as normal */
        width: 100%; /* Full width */
        margin-top: 20px;
    }

    .previous-button,
    .next-button {
        width: auto; /* Auto width for buttons */
        margin: 0; /* Remove margin */
    }

    .arrow-button {
        font-size: 20px; /* Adjust size for smaller arrows */
        padding: 5px; /* Reduce padding */
    }
    
}

@media (max-width: 480px) {
    .preferences-title {
        font-size: 22px; /* Increased title font size for very small screens */
    }

    .preferences-subtitle {
        font-size: 14px; /* Increased subtitle font size for very small screens */
    }

    .category-title {
        font-size: 16px; /* Even smaller category title */
    }

    .previous-button,
    .next-button {
        font-size: 14px; /* Smaller button text */
    }

    .arrow-button {
        font-size: 16px; /* Further reduce arrow size for very small screens */
        padding: 3px; /* Further reduce padding */
    }
}
</style>

