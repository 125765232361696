<template>
    <div v-if="isVisible" class="modal-overlay">
      <div class="modal-container">
        <button class="modal-close" @click="closeModal">X</button>
        <h2 class="modal-title">Verifique seu e-mail</h2>
        <p class="modal-message">
          Por favor, verifique seu e-mail para continuar o processo.
        </p>
        <button class="modal-confirm" @click="handleOk">OK</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      handleOk() {
        this.$emit('ok');
      }
    }
  };
  </script>
  
  <style scoped>
  /* Styles as before */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .modal-message {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .modal-confirm {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .modal-confirm:hover {
    background-color: #45a049;
  }
  </style>
  